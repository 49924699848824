.items-container{
    height: 90%;
    position: absolute;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    padding: inherit;
}

.items-title__container{
    margin-left: auto;
    margin-right: auto;
    width: inherit;
    color: green;
    background-color: black;
}

.items-list__container{
    margin-left: auto;
    margin-right: auto;
    width: inherit;
    height: inherit;
    overflow-y: scroll;
    word-break: break-all;
    border-style: ridge;
    border-width: thick;
    color: green;
    background-color: black;
}

.toolbar_typography {
    flex-grow: 1
}

.view-title{
    border-style: ridge;
    border-width: thick;
}
